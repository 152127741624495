import { put, call, getContext, select } from "redux-saga/effects";
import * as R from "ramda";
import { UNAUTHORIZED } from "state/actions/auth";

import { HEADER_AUTHORIZATION } from "consts";
import { RequestError, setError } from "state/actions/error";
import { selectIAVToken } from "state/selectors";
import { formatBearerAuthentication } from "state/utils";

export const getError = (exception: RequestError) => {
  const { response } = exception;
  let error;
  if (response) {
    if (response.status === 404) {
      error = { message: "resource not found" };
    } else if (response.data) {
      error = response.data.error
        ? response.data.error
        : { message: "error undefined" };
    } else {
      error = { message: "error undefined" };
    }
  } else {
    error = {
      message: exception.message,
      number: R.path(["error", "number"], exception),
    };
  }
  return error;
};

export const shortenAccountNumberMask = (
  iavAccountNumberMask: string | undefined
) => {
  if (iavAccountNumberMask && iavAccountNumberMask.length > 4) {
    return iavAccountNumberMask.slice(-4);
  }
  return iavAccountNumberMask;
};

export const wrapSaga = (fn: any) =>
  function*({ type, payload }: any = {}) {
    const { onError, onSuccess, setSubmitting, ...restOfParam } = payload;

    try {
      const result = yield call(fn, { type, payload: restOfParam });
      if (onSuccess) {
        if (setSubmitting) {
          setSubmitting(false);
        }
        onSuccess(result);
      }
    } catch (exception) {
      if (setSubmitting) {
        setSubmitting(false);
      }
      if (exception.response && exception.response.status === 403) {
        yield put({ type: UNAUTHORIZED });
        if (onError) {
          const error = { message: "your session has expired" };
          onError(error);
        }
      } else {
        const error = getError(exception);
        if (onError) {
          onError(error);
        } else {
          yield put(setError(error));
        }
      }
    }
  };

export function* getSdk() {
  const sdk = yield getContext("sdk");
  const iavToken = yield select(selectIAVToken);
  if (iavToken) {
    const headerAuthorization = formatBearerAuthentication(iavToken);
    sdk.setExtraRequestHttpOptions({
      headers: { [HEADER_AUTHORIZATION]: headerAuthorization },
    });
  }
  return sdk;
}

export function* getIAVServer() {
  return yield getContext("iavServer");
}

export function* getAdminUserId() {
  return yield getContext("adminUserId");
}