import { call, select, takeEvery } from "redux-saga/effects";
import { filter, map, omit, toPairs } from "ramda";
import { PayloadAction } from "typesafe-actions/dist/type-helpers";
import { A2A_MICRO_SERVICE, ACTIVE_DDA_STATUS } from "consts";

import { ADD_EXTERNAL_ACCOUNTS } from "../actions/externalAccount";

import {
  selectFspId,
  selectIAVToken,
  selectInstitution,
  selectPayerId,
  selectSelectionAccount,
  selectUserId,
} from "../selectors";
import {
  getAdminUserId,
  getIAVServer,
  getSdk,
  wrapSaga,
  shortenAccountNumberMask,
} from "./utils";
import { ExtDdaAccount, Optional } from "../../types";

const confirmAddExternalAccountsSaga = wrapSaga(function* ({
  payload,
}: PayloadAction<string, any>) {
  const pairs = toPairs(payload);
  const pairsToAdd = filter(([, shouldLink]) => shouldLink)(pairs);
  const accountsToLink = map(([accountId]) => accountId, pairsToAdd);

  const payerId = yield select(selectPayerId);
  const userId = yield select(selectUserId);
  const fspId = yield select(selectFspId);
  const { name } = yield select(selectInstitution);

  const sdk = yield getSdk();
  let adminUserId = yield getAdminUserId();
  if (!adminUserId) {
    const adminUserIdUrl = "/auth/iav/adminuserid";
    const iavServer = yield getIAVServer();
    const iavToken = yield select(selectIAVToken);
    const { id } = yield call(iavServer.get, adminUserIdUrl, iavToken);
    adminUserId = id;
  }
  const fsp = sdk.fsps(fspId);

  const result = [];
  for (const accountToLink of accountsToLink) {
    const selectedAccount: Optional<ExtDdaAccount> = yield select(
      selectSelectionAccount,
      accountToLink
    );

    if (!selectedAccount) continue;

    let externalDda;

    // Check if alreadyLinked is either null or undefined.
    // If true, it means alreadyLinked doesn't exist or hasn't been initialized.
    // We also check if alreadyLinked is not an object to handle cases where it might be incorrectly typed.
    if (
      selectedAccount.alreadyLinked == null ||
      typeof selectedAccount.alreadyLinked !== "object"
    ) {
      const adminIdPayload = { adminUserId };
      try {
        yield sdk.rtns(selectedAccount.achRtn).get(adminIdPayload);
      } catch (error) {
        yield sdk.rtns.create(
          { routingNumber: selectedAccount.achRtn, name },
          adminIdPayload
        );
      }
      const {
        accountNumberLastFour,
        accountNbrMask,
        ...restOfSelectedAccount
      } = selectedAccount;
      const iavAccountNumberMask =
        !!accountNumberLastFour && accountNumberLastFour !== accountNbrMask
          ? accountNumberLastFour
          : undefined;

      externalDda = yield fsp.payers(payerId).externalDdas.create(
        {
          ...omit(
            ["availBalance", "id", "alreadyLinked"],
            restOfSelectedAccount
          ),
          externalDdaStatus: ACTIVE_DDA_STATUS,
          description: "",
          microService: A2A_MICRO_SERVICE,
          iavAccountNumberMask: shortenAccountNumberMask(iavAccountNumberMask),
        },
        { userId }
      );

      if (!externalDda.nickName) {
        externalDda = yield fsp
          .externalDdas(externalDda.id)
          .update({ nickName: externalDda.externalFiName }, { userId });
      }
    } else {
      externalDda = selectedAccount.alreadyLinked;
    }
    externalDda.accountNumber = selectedAccount.accountNumber;

    result.push(externalDda);
  }

  return result;
});

function* externalAccountSaga() {
  yield takeEvery(ADD_EXTERNAL_ACCOUNTS, confirmAddExternalAccountsSaga);
}

export default externalAccountSaga;
